<template>
  <v-row>
    <v-col
      cols="12"
      class="mb-6"
    >
      <v-card>
        <v-card-title>
          การจอง <v-spacer></v-spacer> <v-btn
            color="primary"
            @click="newbooking"
          >
            เพิ่มการจอง
          </v-btn>
        </v-card-title>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col cols="auto">
            <v-btn
              icon
              color="info"
              @click="refreshbooking"
            >
              <v-icon>{{ icons.mdiReload }}</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="search"
              label="ค้นหา การจอง"
              dense
              :prepend-icon="icons.mdiMagnify"
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
          >
            <v-btn
              depressed
              color="primary"
              @click="incoming"
            >
              การจองที่กำลังจะถึง
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-0 pb-1 px-2 align-center">
          <v-col>
            <v-data-table
              :search="search"
              :headers="headers"
              :items="bookings"
              :items-per-page="25"
              class="elevation-1"
              :loading="tableloader"
            >
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  :color="item.status === 'waiting' ? 'warning': item.status === 'confirm' ? 'success' : item.status === 'waitingc' ? 'info': 'error'"
                >
                  {{ getstatustext(item.status) }}
                </v-chip>
              </template>
              <template v-slot:[`item.slip`]="{ item }">
                <v-img
                  v-if="item.slip"
                  height="50"
                  width="50"
                  contain
                  :src="'https://nextbooking.ap-south-1.linodeobjects.com/' + item.slip"
                ></v-img>
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  icon
                  color="info"
                  @click="editbooking(item)"
                >
                  <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
                </v-btn>
                <!-- <v-btn
                  icon
                  color="error"
                  @click="deletehealthmanual(item)"
                >
                  <v-icon>{{ items.mdiDeleteOutline }}</v-icon>
                </v-btn> -->
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        :retain-focus="false"
        persistent
        fullscreen
        max-width="100%"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5">การจอง</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="currentbooking">
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="currentbooking.name"
                    label="ชื่อลูกค้า*"
                    dense
                    hide-details
                    outlined
                    :rules="[v=> !!v || 'Required']"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-text-field
                    v-model="currentbooking.adults"
                    label="ผู้ใหญ่"
                    dense
                    hide-details
                    outlined
                    type="Number"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="6"
                >
                  <v-text-field
                    v-model="currentbooking.childrens"
                    label="เด็ก"
                    dense
                    hide-details
                    outlined
                    type="Number"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="currentbooking.phone"
                    label="เบอร์โทร*"
                    dense
                    hide-details
                    outlined
                    :rules="[v=> !!v || 'Required']"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                >
                  <VueCtkDateTimePicker
                    v-model="currentbooking.datetime"
                    xoutput-format="DD-MM-YYYY HH:mm"
                    formatted="LLL"
                    :minute-interval="15"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-select
                    v-model="currentbooking.status"
                    label="สถานะ"
                    dense
                    hide-details
                    outlined
                    :rules="[v=> !!v || 'Required']"
                    item-text="label"
                    item-value="value"
                    :items="statusitems"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-img
                    v-if="currentbooking.slip"
                    width="300"
                    :src="'https://nextbooking.ap-south-1.linodeobjects.com/' + currentbooking.slip"
                  ></v-img>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="sliptmp"
                    label="ภาพสลิป"
                    filled
                    :prepend-icon="icons.mdiCamera"
                    outlined
                    hide-details
                    dense
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="savebooking"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-row>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiClose,
  mdiCamera,
  mdiMagnify,
  mdiReload,
} from '@mdi/js'
import moment from 'moment'

export default {
  setup() {
    return {
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiClose,
        mdiCamera,
        mdiMagnify,
        mdiReload,
      },
    }
  },
  data() {
    return {
      search: '',
      dialog: false,
      imgdialog: false,
      sliptmp: null,
      statusitems: [
        { label: 'รอสลิป', value: 'waiting' },
        { label: 'ส่งสลิปแล้ว', value: 'waitingc' },
        { label: 'ยืนยันการจอง', value: 'confirm' },
        { label: 'ยกเลิก', value: 'cancel' },
      ],
      headers: [
        { text: 'เลขการจอง', align: 'start', value: 'no' },
        { text: 'ชื่อลูกค้า', align: 'start', value: 'name' },
        { text: 'เบอร์โทร', value: 'phone' },
        { text: 'เวลาจอง', value: 'datetime' },
        { text: 'สถานะ', value: 'status' },
        { text: 'สลิป', value: 'slip' },
        { text: 'Action', value: 'action', width: '100' },
      ],
      bookings: [],
      booking: '',
      dialogmode: 'new',
      tableloader: false,
      currentbooking: {
      },
      bookingquery: {

      },
    }
  },
  async created() {
    this.getbookings()
  },
  methods: {
    editbooking(item) {
      this.dialogmode = 'edit'
      this.currentbooking = item
      this.dialog = true
    },
    newbooking() {
      this.dialogmode = 'new'
      this.dialog = true
      this.currentbooking = {
        datetime: null,
        status: 'waiting',
        adults: 1,
        childrens: 0,
      }
    },
    async getbookings() {
      this.tableloader = true
      let { data: { bookings } } = await this.axios.get('/admin/bookings', { params: this.bookingquery })
      bookings = bookings.map(booking => ({
        ...booking,
        action: '',
        datetime: moment(booking.datetime).format('YYYY-MM-DD HH:mm'),
        updatedAt: moment(booking.updatedAt).format('YYYY-MM-DD HH:mm'),
        createdAt: moment(booking.createdAt).format('YYYY-MM-DD HH:mm'),
      }))
      this.bookings = bookings
      this.tableloader = false
    },
    getstatustext(statusvalue) {
      const status = this.statusitems.find(item => item.value === statusvalue)

      return status.label
    },
    incoming() {
      this.bookingquery = {
        datetime: new Date(),
      }
      this.getbookings()
    },
    refreshbooking() {
      this.bookingquery = {}
      this.getbookings()
    },
    async savebooking() {
      if (this.$refs.currentbooking.validate()) {
        const currenntbooking = new FormData()
        currenntbooking.append('name', this.currentbooking.name)
        currenntbooking.append('phone', this.currentbooking.phone)
        currenntbooking.append('datetime', this.currentbooking.datetime)
        currenntbooking.append('status', this.currentbooking.status)
        if (this.sliptmp) {
          currenntbooking.append('img', this.sliptmp)
        }
        try {
          if (this.dialogmode === 'edit') {
            await this.axios.put('/admin/booking', currenntbooking, { params: { _id: this.currentbooking._id } })
          } else {
            await this.axios.post('/admin/booking', currenntbooking)
          }
          await this.getbookings()
        } catch (error) {
          if (error.response.status === 401) {
            this.$cookies.set('t', '')
            this.$router.push({ name: 'Login' })
          }
        }
        this.sliptmp = null
        this.dialog = false
      }
    },
  },
}
</script>
